// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-a-better-album-ranking-algorithm-mdx": () => import("./../../../src/pages/blog/a-better-album-ranking-algorithm.mdx" /* webpackChunkName: "component---src-pages-blog-a-better-album-ranking-algorithm-mdx" */),
  "component---src-pages-blog-a-form-fitted-z-shell-index-mdx": () => import("./../../../src/pages/blog/a-form-fitted-z-shell/index.mdx" /* webpackChunkName: "component---src-pages-blog-a-form-fitted-z-shell-index-mdx" */),
  "component---src-pages-blog-components-mdx-secret-weapon-example-counter-tsx": () => import("./../../../src/pages/blog/components-mdx-secret-weapon/ExampleCounter.tsx" /* webpackChunkName: "component---src-pages-blog-components-mdx-secret-weapon-example-counter-tsx" */),
  "component---src-pages-blog-components-mdx-secret-weapon-index-mdx": () => import("./../../../src/pages/blog/components-mdx-secret-weapon/index.mdx" /* webpackChunkName: "component---src-pages-blog-components-mdx-secret-weapon-index-mdx" */),
  "component---src-pages-blog-customizing-tachyons-mdx": () => import("./../../../src/pages/blog/customizing-tachyons.mdx" /* webpackChunkName: "component---src-pages-blog-customizing-tachyons-mdx" */),
  "component---src-pages-blog-fetch-with-timeout-mdx": () => import("./../../../src/pages/blog/fetch-with-timeout.mdx" /* webpackChunkName: "component---src-pages-blog-fetch-with-timeout-mdx" */),
  "component---src-pages-blog-functional-first-accordions-mdx": () => import("./../../../src/pages/blog/functional-first-accordions.mdx" /* webpackChunkName: "component---src-pages-blog-functional-first-accordions-mdx" */),
  "component---src-pages-blog-no-red-squigglies-for-semicolons-mdx": () => import("./../../../src/pages/blog/no-red-squigglies-for-semicolons.mdx" /* webpackChunkName: "component---src-pages-blog-no-red-squigglies-for-semicolons-mdx" */),
  "component---src-pages-blog-on-declarative-styling-mdx": () => import("./../../../src/pages/blog/on-declarative-styling.mdx" /* webpackChunkName: "component---src-pages-blog-on-declarative-styling-mdx" */),
  "component---src-pages-blog-styling-links-for-print-mdx": () => import("./../../../src/pages/blog/styling-links-for-print.mdx" /* webpackChunkName: "component---src-pages-blog-styling-links-for-print-mdx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-colophon-tsx": () => import("./../../../src/pages/colophon.tsx" /* webpackChunkName: "component---src-pages-colophon-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-uses-tsx": () => import("./../../../src/pages/uses.tsx" /* webpackChunkName: "component---src-pages-uses-tsx" */)
}

